var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"step-field-form"},[_c('h3',{staticClass:"step-section-title"},[_vm._v("Duplex Details")]),_c('GeneralCondition',{model:{value:(_vm.generalCondition),callback:function ($$v) {_vm.generalCondition=$$v},expression:"generalCondition"}}),_c('FloorConstruction',{model:{value:(_vm.floorConstruction),callback:function ($$v) {_vm.floorConstruction=$$v},expression:"floorConstruction"}}),_c('WallConstruction',{model:{value:(_vm.wallConstruction),callback:function ($$v) {_vm.wallConstruction=$$v},expression:"wallConstruction"}}),_c('RoofConstruction',{model:{value:(_vm.roofConstruction),callback:function ($$v) {_vm.roofConstruction=$$v},expression:"roofConstruction"}}),_c('ValidationProvider',{attrs:{"name":"Approximate Building Year","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Calendar',{attrs:{"fieldId":"approximateBuildYear","error":errors[0],"placeholder":"Select approx build year","view":"year","label":"Approximate Building Year"},model:{value:(_vm.approximateBuildYear),callback:function ($$v) {_vm.approximateBuildYear=$$v},expression:"approximateBuildYear"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Estimated Value / Current Book Value","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"estimatedValue","error":errors[0],"placeholder":"Enter estimated value","mode":"currency","currency":"AUD","label":"Estimated Value / Current Book Value"},model:{value:(_vm.estimatedValue),callback:function ($$v) {_vm.estimatedValue=$$v},expression:"estimatedValue"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Current Rental","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"currentRental","error":errors[0],"placeholder":"Enter current rental","mode":"currency","currency":"AUD","label":"Current Rental"},model:{value:(_vm.currentRental),callback:function ($$v) {_vm.currentRental=$$v},expression:"currentRental"}})]}}])}),_c('Bedrooms',{model:{value:(_vm.bedrooms),callback:function ($$v) {_vm.bedrooms=$$v},expression:"bedrooms"}}),_c('Bathrooms',{model:{value:(_vm.bathrooms),callback:function ($$v) {_vm.bathrooms=$$v},expression:"bathrooms"}}),_c('Parking',{model:{value:(_vm.carParks),callback:function ($$v) {_vm.carParks=$$v},expression:"carParks"}}),_c('ValidationProvider',{attrs:{"name":"Internal Floor Area (m²)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"floorArea","error":errors[0],"placeholder":"Enter internal floor area","suffix":"m²","label":"Internal Floor Area (m²)"},model:{value:(_vm.floorArea),callback:function ($$v) {_vm.floorArea=$$v},expression:"floorArea"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Other","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputTextarea',{attrs:{"fieldId":"other","error":errors[0],"placeholder":"e.g. Architecturally Designed, Recently Renovated, Heritage Listed ...","label":"Other","rows":10},model:{value:(_vm.other),callback:function ($$v) {_vm.other=$$v},expression:"other"}})]}}])}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Property Features")]),_c('PropertyFeatures',{attrs:{"featureType":"Residential"},model:{value:(_vm.propertyFeatures),callback:function ($$v) {_vm.propertyFeatures=$$v},expression:"propertyFeatures"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }